import React, { useState, useEffect, useRef, useMemo } from "react";

import './Certificate.css';

import { RegisterModalData, RegisterModalObserver, SetModalState } from "interface/PopUp";

import { Svg_Close } from "services/SvgFile";

import { colorIcon } from "fixedData";
import { Reg_CourseCertificate } from "services/Register";
import Comp_InputFile from "components/InputFile";

export default function PopUP_Certificate(props){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ certificate, setCertificate ] = useState('');
    const [ certificateStatus, setCertificateStatus ] = useState(false);
    const [ certificateDate, setCertificateDate ] = useState(60);
    const [ certificateName, setCertificateName ] = useState(10);
    const [ width, setWidth ] = useState(0);
    const [ height, setHeight ] = useState(0); 
    const [ isDragging, setIsDragging ] = useState(false); 

    const certificateNameElement = useRef(null);
    const certificateDateElement = useRef(null);   
    const certificateElement = useRef(null);    
    
    function ClosePopUp(){
        setCertificate('');
        setCertificateDate(60);
        setCertificateName(10);
        setWidth(0);
        setHeight(0);
        SetModalState('Certificate', false);
    }
            
    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_CourseCertificate(modalData.id, certificate, certificateDate, certificateName, certificateElement.current.clientWidth, certificateElement.current.clientHeight, certificateElement.current.naturalWidth, certificateElement.current.naturalHeight, props.CallbackSuccess, props.CallbackError);
    }

    // Slide the certificate name and date to the desired Y position
    useEffect(()=>{
        RegisterModalData('Certificate', setModaldata);
        RegisterModalObserver('Certificate', setShowPopUp); 

        document.addEventListener('mousemove', (event)=>{          
                
            if(certificateNameElement.current){
                if(certificateNameElement.current.classList.contains('active')){
                    const ClampedY = Math.min(Math.max(event.clientY - certificateNameElement.current.clientHeight / 2 - certificateElement.current.getBoundingClientRect().top, 0), certificateElement.current.clientHeight - certificateNameElement.current.clientHeight);
                    setCertificateName(ClampedY);
                    
                }
            }
            if(certificateDateElement.current){
                if(certificateDateElement.current.classList.contains('active')){
                    const ClampedY = Math.min(Math.max(event.clientY - certificateDateElement.current.clientHeight / 2 - certificateElement.current.getBoundingClientRect().top, 0), certificateElement.current.clientHeight - certificateDateElement.current.clientHeight);
                    setCertificateDate(ClampedY);
                }
            }            
        });

        document.addEventListener('mouseup', (event)=>{            
            if(certificateNameElement.current){
                certificateNameElement.current.classList.remove('active');   
                setIsDragging(false);             
            }
            if(certificateDateElement.current){
                certificateDateElement.current.classList.remove('active');
                setIsDragging(false);
            }
        });

        document.addEventListener('mousedown', (event)=>{
            if(certificateNameElement.current){
                if(event.target === certificateNameElement.current){
                    certificateNameElement.current.classList.add('active');
                    setIsDragging(true);
                }
            }
            if(certificateDateElement.current){
                if(event.target === certificateDateElement.current){
                    certificateDateElement.current.classList.add('active');
                    setIsDragging(true);
                }
            }
        }
        );

        return ()=>{
            document.removeEventListener('mousemove', ()=>{});
            document.removeEventListener('mouseup', ()=>{});
            document.removeEventListener('mousedown', ()=>{});
        }

    }, []);

    useEffect(()=>{
        if(showPopUp){
            setCertificate(modalData.certificate);
            setCertificateStatus(false);
            setCertificateDate(modalData.certificate_date != 0 ? modalData.certificate_date : 60);
            setCertificateName(modalData.certificate_name != 0 ? modalData.certificate_name : 10);                       
        }       
    }, [showPopUp]); 

    const certificateImage = useMemo(() => {
        return certificate ? certificateStatus ? URL.createObjectURL(certificate) : certificate : '';
    }, [certificate]);

    return (
        (showPopUp ?
            <form className="PopUp" onSubmit={ SaveData }>
                <div className="all Certificate">
                    <div className="div_data type_div">
                        <div className="title" style={ { borderColor: colorIcon } }></div>
                        <div className="close" style={ { borderColor: colorIcon } } onClick={ ()=>{ ClosePopUp(); } }>
                            <Svg_Close color={ colorIcon } className="icons" />
                        </div>
                    </div>
                    <div className="div_data">
                        <div className="content list_btn">
                            <div className="">
                                Para ajustar a posição, basta clicar e arrastar na opção desejada
                            </div>
                            <div className=""></div>
                                <Comp_InputFile index="not" setValue={ setCertificate } value={ certificate } setStatus={ setCertificateStatus } status={ certificateStatus } OpenFile={ props.OpenFile } thumbnail={ certificate } title1="Adicionar certificado" title2="Certificado adicionado" />
                            </div>
                        </div>
                        {
                            certificate ?
                            
                                <div className="dragContainer" >
                                    {/* <div className="certificate_file" style={{ backgroundImage: 'url("' + certificateImage + '")' }} ref={certificateElement}  >
                                    </div> */}
                                    <img src={ certificateImage } className="certificate_file"  ref={ certificateElement }/>
                                    <div className="dragElements" >
                                        <div className="certificate_name_position" style={{ top: certificateName + 'px' }} ref={ certificateNameElement }  >
                                            <p>Nome do aluno</p>
                                        </div>
                                        <div className="certificate_date_position" style={{ top: certificateDate + 'px' }} ref={ certificateDateElement } >
                                        <p>Data do curso</p>
                                        </div>
                                    </div>
                                </div>
                                
                            : null
                        }                        
                        <div className="btn_save">
                            <button type="submit">
                                Salvar
                            </button>
                        </div>
                    </div>
            </form>
        : <React.Fragment></React.Fragment>)
    );
}
